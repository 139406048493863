import React from 'react'
import firebase from './firebase/firebaseConfig'
import ProtectedRoute from './ProtectedRoute'
import AuthRoute from './AuthRoute'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Calculator from './containers/Calculator/Calculator'


function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path='/calculator' component={Calculator} />
          <Route path='*' component={Calculator} />
        </Switch>
      </Router>
    </>
  )
}

export default App
