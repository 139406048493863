import React, { useState, useEffect } from 'react'
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle'
import TextInputContainer from '../../components/TextInputContainer/TextInputContainer'
import './Calculator.css'
import * as FaIcons from 'react-icons/fa'
import CalculatorFooter from './CalculatorFooter'
import firebase from '../../firebase/firebaseConfig'
import html2canvas from 'html2canvas'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import PDFDownloadLink from '@react-pdf/renderer';
import { jsPDF } from 'jspdf'
import axios from "axios";
import { withAlert } from "react-alert";

function Calculator({alert}) {
  // TRIP INFO
  const [lineHaulRevenue, setLineHaulRevenue] = useState('')
  const [miles, setMiles] = useState('')
  const [deadhead, setDeadhead] = useState('')
  const [roadDaysForTrip, setRoadDaysForTrip] = useState(1)
  const [avgCostPerMile, setAvgCostPerMile] = useState('')

  // EXPENSES: FIXED COSTS
  const [truckTrailerPayments, setTruckTrailerPayments] = useState('')
  const [insurance, setInsurance] = useState('')
  const [rentOrMortgage, setRentOrMortgage] = useState('')
  const [otherFixedCost, setOtherFixedCost] = useState('')

  // EXPENSES: VARIABLE COSTS
  const [driverPay, setDriverPay] = useState('')
  const [driverPayType, setDriverPayType] = useState('1')
  const [driverPayTotal] = useState(0)
  const [fuelCost, setFuelCost] = useState(3.37)
  const [fuelRate, setFuelRate] = useState(3.37)
  const [datRate, setDatRate] = useState(2.76)
  const [totalFuelCost, settotalFuelCost] = useState('')
  const [mealsPerDiem, setMealsPerDiem] = useState('')
  const [miscellaneous, setMiscellaneous] = useState('')

  const [email, setemail] = useState('')

  let fixedExpenseTotal =
    +truckTrailerPayments + +insurance + +rentOrMortgage + +otherFixedCost
  let driverPayTotalCal = 0
  if(driverPayType === '1')
  {
      driverPayTotalCal = (+driverPay * (+miles + +deadhead)).toFixed(2)
      console.log(driverPayTotalCal)
  }
  if(driverPayType === '2')
  {
      driverPayTotalCal = +lineHaulRevenue*(+driverPay / 100).toFixed(2)
      console.log(driverPayTotalCal)
  }
  console.log(driverPayType)
  console.log(driverPayTotalCal)
  let variableExpenseTotal =
    +driverPayTotalCal +
    +totalFuelCost +
    // +((fuelCost * (+miles + +deadhead)) / 6) +
    +mealsPerDiem +
    +miscellaneous

  let totalExpenses =
    (fixedExpenseTotal / 31) * roadDaysForTrip + variableExpenseTotal
  console.log(totalExpenses)
  let proRatedFixedcost = (fixedExpenseTotal/31 * roadDaysForTrip).toFixed(2)
  let ratePerMile = lineHaulRevenue / miles
  let totalCostPerMile = !avgCostPerMile
    ? +totalExpenses / miles
    : +avgCostPerMile
  let profitLostPerMile = ratePerMile - totalCostPerMile

  useEffect(() => {
    setMealsPerDiem(66 * +roadDaysForTrip)
  }, [roadDaysForTrip])

  useEffect(() => {
    settotalFuelCost(+((fuelCost * (+miles + +deadhead)) / 6).toFixed(2))
  }, [miles, deadhead, fuelCost])

  useEffect(() => {
    let saved_email = window.localStorage.getItem('emailForSignIn')
    setemail(saved_email)
  }, [])

  useEffect(() => {
    let DB = firebase.firestore()
    let saved_email = window.localStorage.getItem('emailForSignIn')
    saved_email
      ? DB.collection('users')
          .doc(saved_email)
          .onSnapshot(function (doc) {
            console.log('Current data: ', doc.data())
            setLineHaulRevenue(
              doc.data().lineHaulRevenue ? +doc.data().lineHaulRevenue : ''
            )
            setMiles(doc.data().miles ? +doc.data().miles : '')
            setDeadhead(doc.data().deadhead ? +doc.data().deadhead : '')
            setRoadDaysForTrip(
              doc.data().roadDaysForTrip ? +doc.data().roadDaysForTrip : ''
            )
            setAvgCostPerMile(
              doc.data().avgCostPerMile ? +doc.data().avgCostPerMile : ''
            )
            // SETTING FIXED COSTS
            setTruckTrailerPayments(
              doc.data().truckTrailerPayments
                ? +doc.data().truckTrailerPayments
                : ''
            )
            setInsurance(doc.data().insurance ? +doc.data().insurance : '')
            setRentOrMortgage(
              doc.data().rentOrMortgage ? +doc.data().rentOrMortgage : ''
            )
            setOtherFixedCost(
              doc.data().otherFixedCost ? +doc.data().otherFixedCost : ''
            )
            // SETTING VARIABLE COSTS
            setDriverPay(doc.data().driverPay ? +doc.data().driverPay : '')
            setMiscellaneous(
              doc.data().miscellaneous ? +doc.data().miscellaneous : ''
            )
          })
      : console.log('not logged in')
  }, [])


    useEffect(() => {
        let DB = firebase.firestore()
        DB.collection('settings')
            .doc('7q1tJsKOs23d7CClByuE')
            .onSnapshot(function (doc) {
                console.log('Current data: ', doc.data())
                setFuelCost(
                    doc.data().fuelprice ? +doc.data().fuelprice : ''
                )
                setFuelRate(
                    doc.data().fuelprice ? +doc.data().fuelprice : ''
                )
                setDatRate(
                    doc.data().dat ? +doc.data().dat : ''
                )

            })
    }, [])

  const sendDataToFirebase = () => {
    let DB = firebase.firestore()

    DB.collection('users')
      .doc(email)
      .collection('calculator')
      .doc(email)
      .set(
        {
          lineHaulRevenue,
          miles,
          deadhead,
          roadDaysForTrip,
          avgCostPerMile,
          // FIXED COSTS
          truckTrailerPayments,
          insurance,
          rentOrMortgage,
          otherFixedCost,
          // VARIABLE COSTS
          driverPay,
          fuelCost,
          mealsPerDiem,
          miscellaneous,
        },
        { merge: true }
      )
      .then(function () {
        alert.show('Your changes have been saved to your template.')
        console.log('Document successfully written!')
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
  }

const MyPdfData ={
    total_profit_loss: (profitLostPerMile * miles).toFixed(2),
    rate_per_mile: ratePerMile.toFixed(2),
    total_cost_per_mile: totalCostPerMile.toFixed(2),
    profit_loss_per_mile: (profitLostPerMile).toFixed(2),
    margin: ((profitLostPerMile / ratePerMile) * 100).toFixed(2),
    line_haul_revenue: lineHaulRevenue,
    miles: miles,
    deadhead: deadhead,
    road_days: roadDaysForTrip,
    truck_payments: truckTrailerPayments,
    insurance: insurance,
    rent_sal: rentOrMortgage,
    ot_monthly_costs: otherFixedCost,
    fixed_pro_rated: fixedExpenseTotal,
    dpay_permile: driverPay,
    dpay_forload: driverPayTotalCal,
    fuel_permile: fuelCost,
    fuel_forload: totalFuelCost,
    meal_perunit: 66,
    meal_forload: mealsPerDiem,
    misc_fees: miscellaneous,
    total_var_exp: variableExpenseTotal.toFixed(2),
    avg_rate_dat: datRate,
}

 const downloadPdfDocument = (rootElementId, downloadFileName) => {
    // const input = document.getElementById(rootElementId)
    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL('image/png')
    //   const pdf = new jsPDF()
    //   var width = pdf.internal.pageSize.getWidth()
    //   var height = pdf.internal.pageSize.getHeight()
    //   pdf.addImage(imgData, 'JPEG', 0, 0, width, height)
    //   pdf.save(`${downloadFileName}.pdf`)
    // })
     //ReactPDF.renderToStream(<MyDocument />);
     //ReactPDF.render(<MyDocument />, `${downloadFileName}.pdf`);
    // return <button onClick={downloadPdfDocument}>Download Pdf</button>
  }

    const generatePDF = () => {
        axios({
            method: 'POST',
            url:
                'https://us-central1-truckdesk-prototype.cloudfunctions.net/generatePdf',
            data: MyPdfData,
            responseType:'arraybuffer'
        })
            .then(function (response) {

                const file = new Blob([response.data], {type: 'application/pdf'});

                const url = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'truck-desk-calculator.pdf');
                document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
                console.log(error)
            })
    }

  const logout = () => {
    console.log('logout')
    window.localStorage.removeItem('emailForSignIn')
  }

    const updateDriverPay = (value) => {
        console.log(value)
    }

    const sendEmail = (email,message,isValid, check= 0) => {
        console.log(check)

        if(isValid && check == 0)
        {
            return;
        }
        if (!email) {
            alert.show('Please do not leave your email empty')
            return;
        }
        if (!message) {
            alert.show('Please add a message to send to us.')
            return;
        }
            fetch(
                'https://us-central1-truckdesk-prototype.cloudfunctions.net/sendMail',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email,
                        message,
                    }),
                }
            ).then(() => {
                alert.show('Email Sent')
            })

    }


    return (
    <>
      <div id='divToDownload' className='calculator-container'>
        <div>
          <h1 className='font-extrabold font-4xl'
            style={{ marginTop: 0, marginBottom: '0rem', textAlign: 'center' }}
          >
            Trucking Load Profit Calculator
          </h1>
          {email ? (
            <p style={{ marginBottom: '0rem', textAlign: 'center' }}>
              logged in as: {email}
            </p>
          ) : null}
          <p style={{ textAlign: 'center', marginBottom: '1rem' }}>
            Powered by <a href='https://truckdesk.us/'>Truckdesk</a>
          </p>
        </div>
        <section className='section-group'>
          <div className='col_1'>
            <h2 className='mt-2 font-bold mb-4 border-b-2 border-brand pb-2 text-brand text-xl'>Trip Info</h2>
            <TextInputContainer
              type='number'
              label='Line Haul Revenue'
              sublabel='money offered for this load'
              value={lineHaulRevenue}
              onChange={(e) => setLineHaulRevenue(e.target.value)}
            >
              <FaIcons.FaDollarSign style={styles} />
            </TextInputContainer>
            <TextInputContainer
              type='number'
              label='Trip Miles'
              sublabel='estimated distance from origin to destination'
              value={miles}
              onChange={(e) => setMiles(e.target.value)}
            />
            <TextInputContainer
              type='number'
              label='Deadhead'
              sublabel='distance truck runs empty to pick-up location'
              value={deadhead}
              onChange={(e) => setDeadhead(e.target.value)}
            />
            <TextInputContainer
              type='number'
              label='Road Days'
              sublabel='estimated number of days to be spent hauling this load'
              value={roadDaysForTrip}
              onChange={(e) => setRoadDaysForTrip(e.target.value)}
            />
            <TextInputContainer
              type='number'
              label='Average Cost Per Mile'
              sublabel='Input number if known. If unknown please complete the expense fields below'
              value={avgCostPerMile}
              onChange={(e) => setAvgCostPerMile(e.target.value)}
            >
              <FaIcons.FaDollarSign style={styles} />
            </TextInputContainer>
          </div>
          <div className='col_2' id='results-column'>
            <h2 className='mt-2 font-bold text-white'>Summary</h2>
            <div className='trip-summary flex flex-row justify-between mb-4 border-b-2'>
              <div>Total Profit/Loss:</div>
              <div className='trip-summary-number'>
                {!isFinite(profitLostPerMile * miles)
                  ? null
                  : ' $' + (profitLostPerMile * miles).toFixed(2)}
              </div>
            </div>
            <div className='trip-summary flex flex-row justify-between mb-4'>
                <div>Rate Per Mile:</div>
              <div className='trip-summary-number'>
                {!isFinite(ratePerMile) ? null : ' $' + ratePerMile.toFixed(2)}
              </div>
            </div>
            <div className='trip-summary flex flex-row justify-between mb-4'>
              <div>Total Cost Per Mile:</div>
              <div className='trip-summary-number'>
                {!isFinite(totalCostPerMile)
                  ? null
                  : ' $' + totalCostPerMile.toFixed(2)}
              </div>
            </div>
            <div className='trip-summary flex flex-row justify-between mb-4'>
              <div>Profit Or Loss Per Mile:</div>
              <div className='trip-summary-number'>
                {!isFinite(profitLostPerMile)
                  ? null
                  : ' $' + profitLostPerMile.toFixed(2)}
              </div>
            </div>

            <div className='trip-summary flex flex-row justify-between mb-4'>
                <div>Margin:</div>
              <div className='trip-summary-number'>
                {!isFinite(profitLostPerMile / ratePerMile)
                  ? null
                  : ' ' +
                    ((profitLostPerMile / ratePerMile) * 100).toFixed(2) +
                    '%'}
              </div>
            </div>
          </div>
        </section>
        <section className='section-group'>
          <div className='col_1'>
            <h2 className='mt-2 font-bold mb-4 border-b-2 border-brand pb-2 text-brand text-xl'>Expenses (Fixed)</h2>
            <TextInputContainer
              type='number'
              label='Truck + Trailer payments'
              sublabel='monthly truck and trailer note'
              value={truckTrailerPayments}
              onChange={(e) => setTruckTrailerPayments(e.target.value)}
            >
              <FaIcons.FaDollarSign style={styles} />
            </TextInputContainer>
            <TextInputContainer
              type='number'
              label='Insurance'
              sublabel='insurance premium per month'
              value={insurance}
              onChange={(e) => setInsurance(e.target.value)}
            >
              <FaIcons.FaDollarSign style={styles} />
            </TextInputContainer>
            <TextInputContainer
              type='number'
              label='Rent'
              sublabel='monthly lot and office rent'
              value={rentOrMortgage}
              onChange={(e) => setRentOrMortgage(e.target.value)}
            >
              <FaIcons.FaDollarSign style={styles} />
            </TextInputContainer>

            <TextInputContainer
              type='number'
              label='Other Monthly Fixed Costs'
              sublabel='eld, back office, phone, legal, subscriptions etc'
              value={otherFixedCost}
              onChange={(e) => setOtherFixedCost(e.target.value)}
            >
              <FaIcons.FaDollarSign style={styles} />
            </TextInputContainer>
            <div className='flex flex-row justify-between mobile-row-col'>
                <div className='text-brand-400 font-bold font-2xl' style={{ color: '#0b76ab' }}>Total Monthly Fixed Cost: ${fixedExpenseTotal}</div>
                <div className='text-brand-400 font-bold font-2xl' style={{ color: '#0b76ab' }}>Pro-Rated Fixed Cost: ${proRatedFixedcost}</div>
            </div>
          </div>

          <div className='col_2'>
            <h2 className='mt-2 font-bold mb-4 border-b-2 border-brand pb-2 text-brand text-xl'>Expenses (Variable)</h2>

            <div className='flex flex-row items-center'>
                <div className='flex flex-col w-full'>
                    <div className='font-bold'>Driver Pay Per Mile</div>
                    <div className='text-gray-500'>$ per mile OR % of load</div>
                    <div className='flex flex-row justify-start items-center'>
                        <div>
                        { driverPayType === '1' ? <FaIcons.FaDollarSign style={styles} /> : <FaIcons.FaPercent style={styles} /> }
                        <input type='number' value={driverPay} className='TextInputContainer_inputElement__e6Zbc mr-2 w-full pl-6 driverpay-input'
                                onChange={(e) => setDriverPay(e.target.value)}/>
                        </div>
                        <div className='text-brand-400 font-bold font-2xl ml-2'>Driver Pay: ${driverPayTotalCal}</div>
                        <div className='mr-2 ml-6'>
                            <input type='radio' name='driver-pay-type' className='mr-2' value='1' onClick={(e) => setDriverPayType(e.target.value)}/>Per Mile
                            <input type='radio' name='driver-pay-type' className='mr-2 ml-2' value='2' onClick={(e) => setDriverPayType(e.target.value)}/>Percentage
                        </div>
                    </div>
                </div>

            </div>
            <TextInputContainer
              type='number'
              label='Fuel'
              sublabel={'diesel estimate @ $'+fuelRate+'/gallon with 6mpg consumption rate'}
              value={totalFuelCost}
              onChange={(e) => settotalFuelCost(e.target.value)}
            >
              <FaIcons.FaDollarSign style={styles} />
            </TextInputContainer>

            <TextInputContainer
              type='number'
              label='Meals'
              sublabel='per diem as allowed by the IRS'
              value={mealsPerDiem}
              onChange={(e) => setMealsPerDiem(e.target.value)}
            >
              <FaIcons.FaDollarSign style={styles} />
            </TextInputContainer>
            <TextInputContainer
              type='number'
              label='Other Variable Costs'
              sublabel='tolls, maintenance, supplies, hotel, repairs, broker fees etc'
              value={miscellaneous}
              onChange={(e) => setMiscellaneous(e.target.value)}
            >
              <FaIcons.FaDollarSign style={styles} />
            </TextInputContainer>
            <div>
              <h1  className='text-brand-400 font-bold font-2xl' style={{ color: '#0b76ab' }}>
                  Total Variable Expense (for this load): $
                {!isFinite(variableExpenseTotal)
                  ? null
                  : variableExpenseTotal.toFixed(2)}
              </h1>
            </div>
          </div>
        </section>
        <section>
            <div className='col_2 bg-brand rounded py-2 mb-6' id='results-column-mobile'>
                <h2 className='mt-2 font-bold text-white'>Summary</h2>
                <div className='trip-summary flex flex-row justify-between mb-4 border-b-2'>
                    <div>Total Profit/Loss:</div>
                    <div className='trip-summary-number'>
                        {!isFinite(profitLostPerMile * miles)
                            ? null
                            : ' $' + (profitLostPerMile * miles).toFixed(2)}
                    </div>
                </div>
                <div className='trip-summary flex flex-row justify-between mb-4'>
                    <div>Rate Per Mile:</div>
                    <div className='trip-summary-number'>
                        {!isFinite(ratePerMile) ? null : ' $' + ratePerMile.toFixed(2)}
                    </div>
                </div>
                <div className='trip-summary flex flex-row justify-between mb-4'>
                    <div>Total Cost Per Mile:</div>
                    <div className='trip-summary-number'>
                        {!isFinite(totalCostPerMile)
                            ? null
                            : ' $' + totalCostPerMile.toFixed(2)}
                    </div>
                </div>
                <div className='trip-summary flex flex-row justify-between mb-4'>
                    <div>Profit Or Loss Per Mile:</div>
                    <div className='trip-summary-number'>
                        {!isFinite(profitLostPerMile)
                            ? null
                            : ' $' + profitLostPerMile.toFixed(2)}
                    </div>
                </div>

                <div className='trip-summary flex flex-row justify-between mb-4'>
                    <div>Margin:</div>
                    <div className='trip-summary-number'>
                        {!isFinite(profitLostPerMile / ratePerMile)
                            ? null
                            : ' ' +
                            ((profitLostPerMile / ratePerMile) * 100).toFixed(2) +
                            '%'}
                    </div>
                </div>
            </div>
        </section>
        <CalculatorFooter
          dat = {datRate}
          saveTemplate={sendDataToFirebase}
          sendContactEmail={(email,msg,isValid,check) => sendEmail(email,msg, isValid,check)}
          pdf={() => generatePDF()}
        />
      </div>
    </>
  )
}

const styles = {
  padding: '5px',
  marginTop: '14px',
  color: 'grey',
  width: '20px',
  textAlign: 'left',
  position: 'absolute',
  fontSize: '24px',
}

export default withAlert()(Calculator)
