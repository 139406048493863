import * as React from 'react'
import firebase from '../../firebase/firebaseConfig'
import './Login.css'
import Button from '../../components/Buttons/Button'

export default function SignIn() {
  // Will try to use previously entered email, defaults to an empty string
  const [email, setEmail] = React.useState(
    window.localStorage.getItem('emailForSignIn') || ''
  )
  const [errorResponse, setErrorResponse] = React.useState('')

  // When this component renders
  React.useEffect(() => {
    // Get the saved email
    const saved_email = window.localStorage.getItem('emailForSignIn')

    // Verify the user went through an email link and the saved email is not null
    if (
      firebase.auth().isSignInWithEmailLink(window.location.href) &&
      !!saved_email
    ) {
      // Sign the user in
      firebase.auth().signInWithEmailLink(saved_email, window.location.href)
    }
  }, [])

  const clearError = () => {
    if (errorResponse != '') {
      setErrorResponse('')
    }
  }

  const updateEmail = (e) => {
    clearError()
    setEmail(e.target.value)
  }

  const trySignIn = async () => {
    // If the user is re-entering their email address but already has a code
    if (
      firebase.auth().isSignInWithEmailLink(window.location.href) &&
      !!email
    ) {
      // Sign the user in
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .catch((err) => {
          switch (err.code) {
            default:
              setErrorResponse('An unknown error has occured')
          }
        })
    } else {
      firebase
        .auth()
        .sendSignInLinkToEmail(email, {
          url: 'https://calculator.truckdesk.com',
          handleCodeInApp: true,
        })
        .then(() => {
          // Save the users email to verify it after they access their email
          window.localStorage.setItem('emailForSignIn', email)
          alert('Email sent! Please check inbox')
        })
        .catch((err) => {
          switch (err.code) {
            default:
              setErrorResponse('An unknown error has occured')
          }
        })
    }
  }

  return (
    <div className='sign_in p-4'>
      <p className='mb-4'>
        To save your inputs please enter in your email. You will get a magic
        link in your email inbox which will log you in so your settings will be
        saved.
      </p>
      <input
        className='mb-4 w-full border-2 px-2 py-4'
        type='text'
        value={email}
        placeholder='Email'
        onChange={updateEmail}
      />
      <div className='error_response'>{errorResponse}</div>
      <Button style={{ width: '100%' }} title='Email Me Link' fx={trySignIn} />
    </div>
  )
}
