import React, { useState, useEffect } from 'react'
import calculatorStyles from './CalculatorFooter.module.css'
import Modal from 'react-modal'
import Referral from '../Referral/Referral'
import PdfEmail from '../PdfEmail/PdfEmail'
import Login from '../Login/Login'
import truckDeskLogo from '../../assets/truckdesk_transparent.png'
import MailchimpSubscribe from "react-mailchimp-subscribe";
import axios from "axios";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const loginModalStyles = {
  content: {
    width: '50%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

function CalculatorFooter(props) {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [emessage, setEMessage] = useState('')

  const [modalIsOpen, setIsOpen] = useState(false)
  const [pdfmodalIsOpen, setPdfIsOpen] = useState(false)
  const [loginModalIsOpen, setLoginModalIsOpen] = useState('')

  const [savedEmail, setSavedEmail] = useState('')

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    let saved_email = window.localStorage.getItem('emailForSignIn')
    setSavedEmail(saved_email)
  }, [])

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openLoginModal() {
    setLoginModalIsOpen(true)
  }

  function closeLoginModal() {
    setLoginModalIsOpen(false)
  }

  function openPdfModal() {
    setPdfIsOpen(true)
  }

  function closePdfLoginModal() {
    setPdfIsOpen(false)
    this.props.pdf()
  }

  const emailRegex = /\S+@\S+\.\S+/;

  const validateEmail = (event) => {
    const email = event.target.value;
    if (emailRegex.test(email)) {
      setIsValid(true);
      setEMessage('');
      setEmail(email)
    } else {
      setIsValid(false);
      setEMessage('Please enter a valid email!');
    }
  };



  const dateObj = new Date()
  const monthName = dateObj.toLocaleString('default', { month: 'long' })
  return (
    <div>
      <p>Note:</p>
      <div className='mobile'>
       <p>- This calculator provides a rough profit estimate.</p>
       <p>- Industry average operating margin in 2020 was 8-12%</p>
       <p>- Average RPM for the month of Oct is ${props.dat}</p>
      </div>
      <div className='desktop'>
        <p>
          - This calculator provides a rough profit estimate. Other Conditions to
          consider when negotiating are weather, weight of load, terrain etc
        </p>
        <p>
          - Trucking industry average operating margin in 2020 was between 8-12%
        </p>
        <p>
          - Average rate per mile for the month of {monthName} is ${props.dat} (according
          to DAT)
        </p>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => console.log('open modal')}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <button style={{ float: 'right', 'font-size': '28px', 'margin-top':'-15px'}} onClick={closeModal}>
          x
        </button>
        <Referral />
      </Modal>
      {/* LOGIN MODAL */}
      <Modal
        isOpen={loginModalIsOpen}
        onAfterOpen={() => console.log('open modal')}
        onRequestClose={closeLoginModal}
        style={loginModalStyles}
        contentLabel='Example Modal'
      >
        <button style={{ float: 'right', 'font-size': '28px', 'margin-top':'-15px'}} onClick={closeLoginModal}>
          x
        </button>
        <Login />
      </Modal>
      {/* PDF MODAL */}
      <Modal
          isOpen={pdfmodalIsOpen}
          onAfterOpen={() => console.log('open modal')}
          onRequestClose={closePdfLoginModal}
          style={loginModalStyles}
          contentLabel='Example Modal'
      >
        <button style={{ float: 'right', 'font-size': '28px', 'margin-top':'-15px'}} onClick={closePdfLoginModal}>
          x
        </button>
        <PdfEmail />
      </Modal>
      <div className='my-6 flex flex-row cal-mobile-btns'>
        <button
          onClick={savedEmail ? props.saveTemplate : openLoginModal}
          className='bg-brand p-2 rounded text-white mr-2 px-4'
        >
          Save Template
        </button>
        <button onClick={props.pdf} className='bg-brand p-2 px-4 rounded text-white mr-2'>
          Save as PDF
        </button>
        <button onClick={openModal} className='bg-brand p-2 px-4 rounded text-white mr-2'>
          Tell a Friend
        </button>
        <button
          onClick={() => window.location.reload()}
          className='bg-brand p-2 rounded text-white mr-2 px-4'
        >
          Refresh Calculator
        </button>
      </div>
      <p>
        Thanks for using our calculator.<span className='desktop'> We hope this helps in you plan
        better in your logistics business. If you have feedback or suggestions
        about this tool, we would love to hear it here:</span><span className='span-mobile'> Send Feedback:</span>
      </p>
      <section className={calculatorStyles.sectionGroup}>
        <div
          className={calculatorStyles.col_1}
          id={calculatorStyles.column_1_textarea}
        >
          <input
            className='w-full p-2 border my-2'
            style={{ width: '100%' }}
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className={`message ${isValid ? 'success' : 'error'}`}>
            {emessage}
          </div>
          <textarea
            className={calculatorStyles.textareaSendEmail}
            style={{ width: '100%' }}
            placeholder='Message'
            id='message'
            name='message'
            rows='5'
            cols='33'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button onClick={() => props.sendContactEmail(email,message, isValid, 1)} className='bg-brand p-2 px-4 rounded text-white mr-2'>
            Send Email
          </button>
        </div>
        <div className={calculatorStyles.col_2}>
          <a target='_blank' href='https://truckdesk.us/'>
            <img
              className={calculatorStyles.truckdeskIcon}
              src={truckDeskLogo}
            />
          </a>
        </div>
      </section>
    </div>
  )
}

export default CalculatorFooter
