import React from 'react'
import './HeaderTitle.css'

function HeaderTitle(props) {
  return (
    <div className='title-container'>
      <h2 style={props.style} className='header-title'>
        {props.title}
      </h2>
    </div>
  )
}

export default React.memo(HeaderTitle)
