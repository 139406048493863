import React, { useState, useEffect } from 'react'
import TextInputContainer from '../../components/TextInputContainer/TextInputContainer'
import Button from '../../components/Buttons/Button'
import axios from 'axios'
import shareOnTwitter from '../../utils/shareOnTwitter'
import shareOnFacebook from '../../utils/shareOnFacebook'
import './PdfEmail.css'
import ReferralButton from '../../components/Buttons/ReferralButton'
import * as FaIcons from 'react-icons/fa'
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { withAlert } from 'react-alert'

function PdfEmail({alert}) {
  const [email, setemail] = useState('')
  const [referralLinkButtonText, setreferralLinkButtonText] =
    useState('Copy Link')

  function copy() {
    const el = document.createElement('input')
    el.value = window.location.href
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  const sendReferralEmail = () => {
    if (!email) {
      alert.error('Please do not leave the email field empty')
    } else {
      fetch(
        'https://us-central1-truckdesk-prototype.cloudfunctions.net/sendReferralMail',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
          }),
        }
      )
      const message = `Your email has been sent to ${email}!`
      alert.show(message)
    }
    console.log('send email')
  }

  const copyReferralLink = () => {
    copy()
    setreferralLinkButtonText('Referral Link Copied!')
    setTimeout(() => {
      setreferralLinkButtonText('Copy Referral Link')
    }, 1500)
  }

  return (
    <>
      <div className='referrals-container p-2'>
        <h2 className='mb-4 font-bold'>Enter email to get PDF</h2>
        <input
          className='w-full px-2 py-4 border-2 mb-4'
          placeholder='yourfriend@gmail.com'
          value={email}
          onChange={(e) => setemail(e.target.value)}
        />
        <Button
          style={{ width: '100%' }}
          className='mb-4'
          title='Save PDF'
          fx={sendReferralEmail}
        />
      </div>
    </>
  )
}

export default withAlert()(PdfEmail)
