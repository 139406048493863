const config = {
    apiKey: 'AIzaSyDv7ytRldFH9eukjWKMIGllz_KIlHhnXbI',
    authDomain: 'truckdesk-prototype.firebaseapp.com',
    databaseURL: 'https://truckdesk-prototype-default-rtdb.firebaseio.com',
    projectId: 'truckdesk-prototype',
    storageBucket: 'truckdesk-prototype.appspot.com',
    messagingSenderId: '248998912285',
    appId: "1:248998912285:web:b7d6d91012b81a8a3001f4",
    measurementId: "G-YWWWW8X3RY"
}

export default config